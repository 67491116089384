exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-magic-tsx": () => import("./../../../src/pages/magic.tsx" /* webpackChunkName: "component---src-pages-magic-tsx" */),
  "component---src-pages-savethedate-tsx": () => import("./../../../src/pages/savethedate.tsx" /* webpackChunkName: "component---src-pages-savethedate-tsx" */),
  "component---src-pages-wedding-hieu-tsx": () => import("./../../../src/pages/wedding-hieu.tsx" /* webpackChunkName: "component---src-pages-wedding-hieu-tsx" */),
  "component---src-pages-wedding-phuong-tsx": () => import("./../../../src/pages/wedding-phuong.tsx" /* webpackChunkName: "component---src-pages-wedding-phuong-tsx" */)
}

